<template>
  <div>
  <div>
      <h2>Search and add a pin</h2>
      <GmapAutocomplete
        @place_changed='setPlace'
        :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
      />
      <button
        @click='addMarker'
      >
        Add
      </button>
    </div>
    <GmapMap
      :center='center'
      :zoom='10'
      style='width:100%;  height: 600px;'
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      />
    </GmapMap>
    </div>
</template>
<script>
  
  export default {
    name: 'Map',
    components: {
    },
    data() {
    return {
      center: { lat: 36.1627, lng: -86.7816 },
      currentPlace: null,
      markers: [{"position":{"lat":36.0505166,"lng":-86.95933079999999}},{"position":{"lat":36.1087947,"lng":-86.7641053}}],
      places: [],
    }
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
     setPlace(place) {
       console.log("place", place)
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
  },
  }
</script>
<style scoped>

  #map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
  }

</style>
